// Form values
export const LIMITED_COMPANY = 'Limited';
export const SOLE_TRADER = 'SoleTrader';
export const PARTNERSHIP = 'Partnership';
export const TRUST_OR_SOCIETY = 'TrustSociety';
export const BUSINESS_TYPE_OTHER = 'Other';

export const AIRPOINTS = 'Airpoints';
export const POINTS_NONE = 'None';

// Property names are used as values, and property values are used as labels.
export const GENDER_OPTIONS = {
  Male: 'Male',
  Female: 'Female',
  NonBinary: 'Non binary',
  Undisclosed: "I'd rather not say"
};

export const INVOICE_DATE_OPTIONS = {
  0: 'End of each month',
  7: '7th of each month',
  15: '15th of each month',
  21: '21st of each month'
};

export const PAYMENT_MONTHLY = 'Monthly';
export const PAYMENT_YEARLY = 'Yearly';

export const CARD_TYPE_ANY = 'Any';
export const CARD_TYPE_VEHICLE = 'Vehicle';
export const CARD_TYPE_DRIVER = 'Driver';
export const CARD_TYPE_DRIVER_VEHICLE = 'DriverVehicle';

export const FUEL_TYPE_ALL = 'All';
export const FUEL_TYPE_DIESEL = 'Diesel';
export const FUEL_TYPE_91 = '91';
export const FUEL_TYPE_ZX = 'Zx Premium';

export const PRODUCT_TYPE_ALL = 'All';
export const PRODUCT_TYPE_CAR = 'Car-related';
export const PRODUCT_TYPE_SHOP = 'Shop';
export const PRODUCT_TYPE_CIGARETTES = 'Cigarettes';
export const PRODUCT_TYPE_EQUIPMENT_HIRE = 'Equipment hire';
export const PRODUCT_TYPE_LPG = 'Lpg';

export const BRAND = 'Z Business';

// In dollars
export const SPECIAL_RATES_THRESHOLD = 12500;

// In years
export const PERSONAL_GUARANTEE_BUSINESS_AGE_THRESHOLD = 2;
export const TOO_MANY_NEW_CARDS_THRESHOLD = 9;

// Credit Check Status
export const CREDIT_CHECK_APPROVED = 'Approved';
export const CREDIT_CHECK_DECLINE = 'Decline';
export const CREDIT_CHECK_APPROVE_WITH_PG = 'Approve with Personal Guarantee';
export const CREDIT_CHECK_FAIL = 'Fail';
export const CREDIT_CHECK_ASSESS = 'Assess';
export const CREDIT_CHECK_REFER = 'Refer';
export const CREDIT_CHECK_ERROR = 'Error';

export const MONTHLY_CARD_LIMITS = [
  { value: null, label: 'No Limit' },
  { value: 250, label: '$250' },
  { value: 500, label: '$500' },
  { value: 750, label: '$750' },
  { value: 1000, label: '$1,000' },
  { value: 1500, label: '$1,500' },
  { value: 2000, label: '$2,000' },
  { value: 2500, label: '$2,500' },
  { value: 10000, label: '$10,000 or more' }
];

export const DAILY_CARD_LIMITS = [
  { value: 100, label: '$100' },
  { value: 150, label: '$150' },
  { value: 250, label: '$250' },
  { value: 300, label: '$300' },
  { value: 400, label: '$400' },
  { value: 500, label: '$500' },
  { value: 750, label: '$750' },
  { value: 1000, label: '$1,000' }
];

// Hero Images
export const HERO_IMAGES = ['/images/hero-13.jpg'];

// To prevent the hero image change between route changes
// get a persistent between full page reload hero image
export const PERSISTENT_HERO_IMAGE =
  HERO_IMAGES[Math.floor(Math.random() * HERO_IMAGES.length)];
